var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-tooltip',{attrs:{"activator":_vm.$refs.bonusUnitSelectBtn,"value":_vm.showError && !!_vm.errorMessageText,"disabled":!_vm.errorMessageText,"text":_vm.errorMessageText,"color":"error","open-on-hover":true,"open-on-click":false,"open-on-focus":false,"top":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"bonusUnitSelectBtn",class:{
          'custom-drop-down': true,
          'border-bottom-not-round': attrs['aria-expanded'] === 'true',
          'error-input': _vm.showError && _vm.errorMessageText,
        },attrs:{"outlined":"","full-width":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"max-width":"10em","overflow":"hidden","text-overflow":"ellipsis"}},[(_vm.selectionItemIsMain)?_c('v-icon',[_vm._v("mdi-star")]):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.selectedItem.name : 'Выберите валюту'))],1),_c('v-icon',{staticStyle:{"height":"21px","width":"21px"},attrs:{"right":"","color":!_vm.disabled ? 'primary' : null}},[_vm._v(" "+_vm._s(attrs['aria-expanded'] === 'false' ? '$iconify_octicon-triangle-down-24' : '$iconify_octicon-triangle-up-24')+" ")])],1)]}}])},[_c('v-list',{staticClass:"list-own-padding"},_vm._l((_vm.bonusListMap),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.internalValue = item.id},"mouseover":function($event){item.setting = true},"mouseleave":function($event){item.setting = false}}},[_c('v-list-item-title',[_c('div',{staticClass:"item-select"},[_c('v-row',{staticClass:"d-flex justify-space-between"},[_c('v-col',[(item.is_main)?_c('v-icon',[_vm._v(" mdi-star ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('v-col',{staticClass:"text-right"},[(item.id === _vm.internalValue && !item.setting)?_c('img',{attrs:{"src":require("@/icons/svg/check.svg")}}):_vm._e(),(item.setting)?_c('img',{attrs:{"src":require("@/icons/svg/settings.svg")},on:{"click":function($event){return _vm.openBonusUnitDialog(item)}}}):_vm._e()])],1)],1)])],1)}),1),_c('v-list',{staticClass:"list-own-padding"},[_c('div',{staticClass:"btn-inside-select",on:{"click":_vm.createBonusesCurrency}},[_c('img',{attrs:{"src":require("@/icons/svg/plus-circle.svg")}}),_vm._v(" Добавить новую валюту ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }